import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    CircularProgress,
    Checkbox,
    FormControlLabel,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import api_amr from '../../services/api_amr';
import { getToken } from '../../utils/utils';
import api from '../../services/api';
import GraficoPrecos from './GraficoPrecos';
import PrecosRegistradosTable from './PrecosRegistradosTable';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    container: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    headerCell: {
        fontSize: '0.7rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    fieldSmall: {
        width: '100%',
        maxWidth: 200, // Diminui o tamanho máximo dos campos
        background: 'white'
    },
    checkboxContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
}));

const RegistradorPrecos = () => {
    const classes = useStyles();

    // Estados
    const [materias, setMaterias] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [fontes, setFontes] = useState([]);
    const [portos, setPortos] = useState([]);
    const [embalagens] = useState([
        { value: 'Big Bag', label: 'Big Bag' },
        { value: 'Granel', label: 'Granel' },
    ]);
    const [data, setData] = useState(new Date().toISOString().split('T')[0]);
    const [dataPagamento, setDataPagamento] = useState(new Date().toISOString().split('T')[0]);
    const [materiaSelecionada, setMateriaSelecionada] = useState(null);
    const [cidadeOrigem, setCidadeOrigem] = useState(null);
    const [cidadeDestino, setCidadeDestino] = useState(null);
    const [empresaSelecionada, setEmpresaSelecionada] = useState('');
    const [fonteSelecionada, setFonteSelecionada] = useState('');
    const [portoSelecionado, setPortoSelecionado] = useState(null);
    const [frete, setFrete] = useState('');
    const [precoCompra, setPrecoCompra] = useState('');
    const [dolar, setDolar] = useState('');
    const [embalagem, setEmbalagem] = useState(null);
    const [volume, setVolume] = useState('');
    const [jurosBRL, setJurosBRL] = useState('');
    const [jurosUSD, setJurosUSD] = useState('');
    const [isAssociado, setIsAssociado] = useState(false);
    const [associados, setAssociados] = useState([]);
    const [associado, setAssociado] = useState(null);
    const [carregando, setCarregando] = useState(false);
    const [atualizar, setAtualizar] = useState(false);
    const [precos, setPrecos] = useState([]);
    const [filter, setFilter] = useState('');
    const [nomesMaterias, setNomesMaterias] = useState([]);
    const [escolhaMaterias, setEscolhaMaterias] = useState(true);
    const [dadosGrafico, setDadosGrafico] = useState([])
    const [produtoNacional, setProdutoNacional] = useState(false)

    const [isSettingProgrammaticValue, setIsSettingProgrammaticValue] = useState(false);
    const [isSubstituindo, setIsSubstituindo] = useState(false)
    const [subID, setSubID] = useState(0)

    // Efeitos colaterais
    useEffect(() => {
        fetchDadosIniciais();
        fetchPrecos();
    }, []);

    // Função para buscar dados iniciais
    const fetchDadosIniciais = async () => {
        try {
            setCarregando(true);
            const token = await getToken();
            const headers = { headers: { 'x-api-key': token } };

            const [
                { data: dolarData },
                { data: materiasData },
                { data: cidadesData },
                { data: empresasFontesData },
                { data: portosData },
                { data: associadosData },
                { data: precosData }
            ] = await Promise.all([
                api.get('/DolarApi'),
                api_amr.get('/return_indice_materias', headers),
                api_amr.get('/return_cidades_brasil', headers),
                api_amr.get('/retorna_empresa_fonte', headers),
                api_amr.get('/return_portos', headers),
                api_amr.get('/get_associados', headers),
                api_amr.get('/retorna_grafico_precos', headers)
            ]);

            setDolar(parseFloat(dolarData));

            setMaterias(
                materiasData.map((item) => ({ value: item.id, label: item.descNome }))
            );
            setCidades(
                cidadesData.map((item) => ({
                    value: item.id,
                    label: `${item.cidade}/${item.UF}`,
                }))
            );
            setEmpresas(
                empresasFontesData.empresas.map((item) => ({
                    value: item.empresa,
                    label: item.empresa,
                }))
            );
            setFontes(
                empresasFontesData.fontes.map((item) => ({
                    value: item.fonte_dados,
                    label: item.fonte_dados,
                }))
            );
            setPortos(
                portosData.map((item) => ({ value: item.id, label: item.nome_porto }))
            );
            setAssociados(
                associadosData.map((item) => ({
                    value: item.id_associado,
                    label: item.name_associado,
                }))
            );

            setDadosGrafico(precosData);

            fetchNomesMaterias();
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            alert('Ocorreu um erro ao buscar os dados.');
        } finally {
            setCarregando(false);
        }
    };

    const fetchNomesMaterias = async () => {
        try {
            const token = await getToken();
            const { data } = await api_amr.get('/get_nomes_materias', {
                headers: { 'x-api-key': token },
            });
            setNomesMaterias(data);
        } catch (error) {
            console.error('Erro ao buscar nomes de matérias:', error);
        }
    };

    const fetchPrecos = async () => {
        try {
            const token = await getToken();
            const { data } = await api_amr.get('/return_precos_registrados', {
                headers: { 'x-api-key': token },
            });
            setPrecos(data);
        } catch (error) {
            console.error('Erro ao buscar preços:', error);
        }
    };
    const handleSubstituiPreco = async () => {


        if ((produtoNacional && !cidadeOrigem) ||
            !materiaSelecionada ||
            !cidadeDestino ||
            !empresaSelecionada ||
            !portoSelecionado ||
            !embalagem ||
            !precoCompra ||
            (isAssociado && !associado)
        ) {
            alert('Por favor, preencha todos os campos obrigatórios.');
            return;
        }

        setAtualizar(true);
        console.log('asdasda', empresaSelecionada.label, fonteSelecionada)

        try {
            const token = await getToken();
            const headers = { headers: { 'x-api-key': token } };

            const response = await api_amr.post(
                '/registra_preco_venda',
                {
                    data,
                    data_pagamento: dataPagamento,
                    empresa: empresaSelecionada.label ? empresaSelecionada.label : empresaSelecionada,
                    id_porto: portoSelecionado.value,
                    origem: produtoNacional ? cidadeOrigem.value : -1,
                    destino: cidadeDestino.value,
                    produto: materiaSelecionada,
                    fonte_dados: fonteSelecionada?.label ? fonteSelecionada.label : null,
                    frete,
                    preco_compra: precoCompra,
                    dolar,
                    embalagem: embalagem.value,
                    volume,
                    juros_brl: jurosBRL,
                    juros_usd: jurosUSD,
                    isAssociado,
                    associado,
                    produto_nacional: produtoNacional,
                },
                headers
            );

            if (response.data === 'OK') {
                alert('Preço registrado com sucesso');
                fetchPrecos();
                handleLimparCampos();
            } else {
                alert('Ocorreu um erro, tente novamente');
            }
        } catch (error) {
            console.error('Erro ao registrar preço:', error);
            alert('Ocorreu um erro ao registrar o preço.');
        } finally {
            setAtualizar(false);
        }
    };

    const handleAdicionaPreco = async (e) => {
        e.preventDefault();

        if ((produtoNacional && !cidadeOrigem) ||
            !materiaSelecionada ||
            !cidadeDestino ||
            !empresaSelecionada ||
            !portoSelecionado ||
            !embalagem ||
            !precoCompra ||
            (isAssociado && !associado)
        ) {
            alert('Por favor, preencha todos os campos obrigatórios.');
            return;
        }

        setAtualizar(true);



        try {
            const token = await getToken();
            const headers = { headers: { 'x-api-key': token } };

            const response = await api_amr.post(
                '/registra_preco_venda',
                {
                    data,
                    data_pagamento: dataPagamento,
                    empresa: empresaSelecionada.label ? empresaSelecionada.label : empresaSelecionada,
                    id_porto: portoSelecionado.value,
                    origem: produtoNacional ? cidadeOrigem.value : -1,
                    destino: cidadeDestino.value,
                    produto: materiaSelecionada,
                    fonte_dados: fonteSelecionada.label ? fonteSelecionada.label : fonteSelecionada,
                    frete,
                    preco_compra: precoCompra,
                    dolar,
                    embalagem: embalagem.value,
                    volume,
                    juros_brl: jurosBRL,
                    juros_usd: jurosUSD,
                    isAssociado,
                    associado,
                    produto_nacional: produtoNacional,
                },
                headers
            );

            if (response.data === 'OK') {
                alert('Preço registrado com sucesso');
                fetchPrecos();

            } else {
                alert('Ocorreu um erro, tente novamente');
            }
        } catch (error) {
            console.error('Erro ao registrar preço:', error);
            alert('Ocorreu um erro ao registrar o preço.');
        } finally {
            setAtualizar(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            const token = await getToken();
            const headers = { headers: { 'x-api-key': token } };

            const response = await api_amr.post(
                '/delete_preco_registrado',
                { id_preco: id },
                headers
            );

            if (response.data === 'OK') {
                alert('Deletado com sucesso');
                fetchPrecos();
            } else {
                alert('Ocorreu um erro, tente novamente');
            }
        } catch (error) {
            console.error('Erro ao deletar preço:', error);
            alert('Ocorreu um erro ao deletar o preço.');
        }
    };

    const filteredData = precos.filter((row) =>
        row.empresa.toLowerCase().includes(filter.toLowerCase()) ||
        row.produto_abreviacao.toLowerCase().includes(filter.toLowerCase())
    );


    // const handleNomeMateriaChange = (event, value) => {
    //     setMateriaSelecionada(value);
    // };

    // const handleNomeMateriaInputChange = (event, value) => {
    //     setMateriaSelecionada({ nome_materia: value });
    // };
    const handleSubstituirValores = () => {
        handleSubstituiPreco()
        if (subID !== 0) {
            handleDelete(subID)
        }
        handleLimparCampos()
    }
    const handleUsarPreco = (dadosPreco) => {
        console.log(dadosPreco);
        setIsSettingProgrammaticValue(true); // Sinalizar que o valor está sendo definido programaticamente
        setIsSubstituindo(true)
        // const fonte = fontes.find((font) => font.label === dadosPreco.fonte_dados) || { label: dadosPreco.fonte_dados };
        // setFonteSelecionada(fonte);



        setProdutoNacional(dadosPreco.produto_nacional !== 0); // Corrigido para verificar !== 0

        // Atualizar campos obrigatórios
        setData(dadosPreco.data ? new Date(dadosPreco.data).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]);
        setDataPagamento(dadosPreco.data_pagamento || new Date().toISOString().split('T')[0]);
        setFrete(dadosPreco.frete !== null ? dadosPreco.frete : '');
        setPrecoCompra(dadosPreco.preco_compra !== null ? dadosPreco.preco_compra : '');
        setDolar(dadosPreco.dolar !== null ? dadosPreco.dolar : '');
        setVolume(dadosPreco.volume !== null ? dadosPreco.volume : '');
        setJurosBRL(dadosPreco.juros_brl !== null ? dadosPreco.juros_brl : '');
        setJurosUSD(dadosPreco.juros_usd !== null ? dadosPreco.juros_usd : '');

        // Atualizar Empresa Selecionada
        const empresa = empresas.find((emp) => emp.value === dadosPreco.empresa)
        console.log('emp2', empresa)
        setEmpresaSelecionada(empresa || null);

        // Atualizar Fonte Selecionada
        setFonteSelecionada(dadosPreco.fonte_dados || '');

        // Atualizar Embalagem Selecionada
        const embalagemSelecionada = embalagens.find((emb) => emb.value === dadosPreco.embalagem);
        setEmbalagem(embalagemSelecionada || null);

        // Atualizar Porto Selecionado
        const portoSelecionado = portos.find((porto) => porto.label === dadosPreco.nome_porto);
        setPortoSelecionado(portoSelecionado || null);

        // Atualizar Cidade de Destino
        const cidadeDestinoSelecionada = cidades.find((cidade) => cidade.value === dadosPreco.destino);
        console.log('cidade', cidadeDestinoSelecionada)
        setCidadeDestino(cidadeDestinoSelecionada || null);

        // Atualizar Cidade de Origem se Produto Nacional for verdadeiro
        if (dadosPreco.produto_nacional !== 0 && dadosPreco.origem !== -1) {
            const cidadeOrigemSelecionada = cidades.find((cidade) => cidade.value === dadosPreco.origem);
            setCidadeOrigem(cidadeOrigemSelecionada || null);
        } else {
            setCidadeOrigem(null);
        }

        if (dadosPreco.associado !== -1) {
            setIsAssociado(true)
            const associadoSelecionado = associados.find((assoc) => assoc.label === dadosPreco.name_associado);
            setAssociado(associadoSelecionado || null);
            setFonteSelecionada(null)
        } else {
            const fonte = fontes.find((font) => font.label === dadosPreco.fonte_dados) || { label: dadosPreco.fonte_dados };
            setFonteSelecionada(fonte || null)
            setIsAssociado(false)
            setAssociado(null);
        }

        if (dadosPreco.formulacao === 0) {
            setEscolhaMaterias(true);
            const materiaSelecionada1 = materias.find((materia) => materia.value === dadosPreco.produto);
            //    console.log('materia1', materiaSelecionada1, 'materias2', materiaSelecionada2)
            setMateriaSelecionada(materiaSelecionada1 || null);
        } else {
            setEscolhaMaterias(false);
            const formulacaoSelecionado = nomesMaterias.find((form) => form.id_formulacao === dadosPreco.produto)
            console.log('materia1', formulacaoSelecionado)
            setMateriaSelecionada(formulacaoSelecionado || null);
        }
        setSubID(dadosPreco.id)
        setTimeout(() => setIsSettingProgrammaticValue(false), 0); // Resetar após atualização
        // Scroll para o topo da página com animação suave
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleLimparCampos = () => {
        setData(new Date().toISOString().split('T')[0]);
        setDataPagamento(new Date().toISOString().split('T')[0]);
        setMateriaSelecionada(null);
        setCidadeOrigem(null);
        setCidadeDestino(null);
        setEmpresaSelecionada('');
        setFonteSelecionada('');
        setPortoSelecionado(null);
        setFrete('');
        setPrecoCompra('');
        setDolar('');
        setEmbalagem(null);
        setVolume('');
        setJurosBRL('');
        setJurosUSD('');
        setIsAssociado(false);
        setAssociado(null);
        setFilter('');
        setEscolhaMaterias(true);
        setProdutoNacional(false);
        setIsSubstituindo(false)
        setSubID(0)
    };

    return (
        <>
            <form onSubmit={handleAdicionaPreco}>
                {carregando && <CircularProgress />}

                {/* Linha de Checkboxes */}
                <Grid container spacing={2} className={classes.checkboxContainer}>
                    <Grid item xs={12} sm={4} md={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isAssociado}
                                    onChange={(e) => setIsAssociado(e.target.checked)}
                                />
                            }
                            label="Associado"
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={escolhaMaterias}
                                    onChange={(e) => setEscolhaMaterias(e.target.checked)}
                                />
                            }
                            label="Escolha Matérias"
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={produtoNacional}
                                    onChange={(e) => setProdutoNacional(e.target.checked)}
                                />
                            }
                            label="Produto Nacional"
                        />
                    </Grid>
                </Grid>

                {/* Campos e Autocompletes */}
                <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={12} sm={6} md={2}>
                        {escolhaMaterias ? (
                            <Autocomplete
                                options={materias}
                                getOptionLabel={(option) => option.label}
                                value={materiaSelecionada || null}
                                onChange={(e, value) => setMateriaSelecionada(value)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Matéria" variant="outlined" fullWidth className={classes.fieldSmall} />
                                )}
                            />
                        ) : (
                            <Autocomplete
                                options={nomesMaterias}
                                getOptionLabel={(option) => option.nome_materia || ''}
                                value={materiaSelecionada || null}
                                onChange={(e, value) => setMateriaSelecionada(value)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Formulação" variant="outlined" fullWidth className={classes.fieldSmall} />
                                )}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <Autocomplete
                            options={embalagens}
                            getOptionLabel={(option) => option.label}
                            value={embalagem || null}
                            onChange={(e, value) => setEmbalagem(value)}
                            renderInput={(params) => (
                                <TextField {...params} label="Embalagem" variant="outlined" className={classes.fieldSmall} />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <Autocomplete
                            options={portos}
                            getOptionLabel={(option) => option.label}
                            value={portoSelecionado || null}
                            onChange={(e, value) => setPortoSelecionado(value)}
                            renderInput={(params) => (
                                <TextField {...params} label="Porto" variant="outlined" className={classes.fieldSmall} />
                            )}
                        />
                    </Grid>



                    {produtoNacional && (
                        <Grid item xs={12} sm={6} md={2}>
                            <Autocomplete
                                options={cidades}
                                getOptionLabel={(option) => option.label}
                                value={cidadeOrigem || null}
                                onChange={(e, value) => setCidadeOrigem(value)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Cidade Origem" variant="outlined" className={classes.fieldSmall} />
                                )}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={2}>
                        <Autocomplete
                            options={cidades}
                            getOptionLabel={(option) => option.label}
                            value={cidadeDestino || null}
                            onChange={(e, value) => setCidadeDestino(value)}
                            renderInput={(params) => (
                                <TextField {...params} label="Cidade Destino" variant="outlined" className={classes.fieldSmall} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Autocomplete
                            options={empresas}
                            getOptionLabel={(option) => option.label || ''}
                            freeSolo
                            value={empresaSelecionada || null}
                            onChange={(e, newValue) => setEmpresaSelecionada(newValue)}
                            onInputChange={(e, newInputValue) => {
                                if (!isSettingProgrammaticValue) {
                                    setEmpresaSelecionada({ label: newInputValue });
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Empresa" variant="outlined" className={classes.fieldSmall} />
                            )}
                        />
                    </Grid>

                    {isAssociado ? (
                        <Grid item xs={12} sm={6} md={2}>
                            <Autocomplete
                                options={associados}
                                getOptionLabel={(option) => option.label}
                                value={associado || null}
                                onChange={(e, value) => setAssociado(value)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Associado" variant="outlined" className={classes.fieldSmall} />
                                )}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={6} md={2}>
                            <Autocomplete
                                options={fontes}
                                getOptionLabel={(option) => option.label || ''}
                                freeSolo
                                value={fonteSelecionada}
                                onChange={(e, newValue) => setFonteSelecionada(newValue)} // Usar para selecionar itens
                                onInputChange={(e, newInputValue) => {
                                    if (!isSettingProgrammaticValue) {
                                        setFonteSelecionada({ label: newInputValue });
                                    }
                                }} // Ignorar durante a definição programática
                                renderInput={(params) => (
                                    <TextField {...params} label="Fonte" variant="outlined" className={classes.fieldSmall} />
                                )}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            label="Volume TON"
                            type="number"
                            value={volume}
                            onChange={(e) => setVolume(e.target.value)}
                            variant="outlined"
                            className={classes.fieldSmall}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            label="Frete R$"
                            type="number"
                            value={frete}
                            onChange={(e) => setFrete(e.target.value)}
                            variant="outlined"
                            className={classes.fieldSmall}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            label="Preço Compra USD"
                            type="number"
                            value={precoCompra}
                            onChange={(e) => setPrecoCompra(e.target.value)}
                            variant="outlined"
                            className={classes.fieldSmall}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            label="Dólar"
                            type="number"
                            value={dolar}
                            onChange={(e) => setDolar(e.target.value)}
                            variant="outlined"
                            className={classes.fieldSmall}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            label="Data"
                            type="date"
                            value={data}
                            onChange={(e) => setData(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            className={classes.fieldSmall}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            label="Data de Pagamento"
                            type="date"
                            value={dataPagamento}
                            onChange={(e) => setDataPagamento(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            className={classes.fieldSmall}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            label="Juros BRL"
                            type="number"
                            value={jurosBRL}
                            onChange={(e) => setJurosBRL(e.target.value)}
                            variant="outlined"
                            className={classes.fieldSmall}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            label="Juros USD"
                            type="number"
                            value={jurosUSD}
                            onChange={(e) => setJurosUSD(e.target.value)}
                            variant="outlined"
                            className={classes.fieldSmall}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={atualizar}
                            fullWidth
                        >
                            {atualizar ? <CircularProgress size={24} /> : 'Salvar'}
                        </Button>

                    </Grid>
                    {isSubstituindo && (
                        <>
                            <Grid Grid item xs={12} sm={6} md={2}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubstituirValores}
                                    fullWidth
                                    disabled={atualizar}
                                >
                                    {atualizar ? <CircularProgress size={24} /> : 'Substituir'}
                                </Button>
                            </Grid>
                            <Grid Grid item xs={12} sm={6} md={2}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleLimparCampos}
                                    fullWidth
                                >
                                    Limpar
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </form>

            <GraficoPrecos dados={dadosGrafico} />

            <TextField
                label="Filtrar por Empresa ou Produto"
                fullWidth
                margin="normal"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                variant="outlined"
            />

            <PrecosRegistradosTable
                filteredData={filteredData}
                handleDelete={handleDelete}
                dolar={dolar}
                handleUsarPreco={handleUsarPreco}
            />
        </>
    );

};

export default RegistradorPrecos;
