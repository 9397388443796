import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import api_amr from '../../services/api_amr';
import { getToken } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    margin: 'auto',
    maxWidth: 1200,
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(4),
  },
}));

const niveisInvestimentoOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
];

const segmentosOptions = [
  {
    name: 'Inseticidas',
    funcoesPrincipais: ['Mastigadores', 'Sugadores', 'Outros'],
  },
  {
    name: 'Herbicidas',
    funcoesPrincipais: [
      'Dessecante',
      'Pré-emergente',
      'Latifolicida',
      'Graminicida',
      'Amplo Espectro',
    ],
  },
  {
    name: 'Fungicidas',
    funcoesPrincipais: [
      'DFC',
      'Ferrugem',
      'Mancha Alvo',
      'Contato',
      'Fisiológico',
      'Multissítio',
    ],
  },
  {
    name: 'Adjuvantes',
    funcoesPrincipais: [
      'Óleo Vegetal',
      'Óleo Mineral',
      'Regulador de pH',
      'Estabilizador de calda',
      'Espalhante Adesivo não iônico',
    ],
  },
  {
    name: 'Inoculantes',
    funcoesPrincipais: [],
  },
  {
    name: 'Enraizadores',
    funcoesPrincipais: [],
  },
];

const Defensivos = () => {
  const classes = useStyles();

  const [defensivo, setDefensivo] = useState(null);
  const [defensivosOptions, setDefensivosOptions] = useState([]);

  const [culturas, setCulturas] = useState([]);
  const [cultura, setCultura] = useState(null);

  const [pragas, setPragas] = useState([]);
  const [praga, setPraga] = useState(null);

  const [doseMinima, setDoseMinima] = useState('');
  const [doseMaxima, setDoseMaxima] = useState('');

  const [segmento, setSegmento] = useState(null);
  const [funcaoPrincipal, setFuncaoPrincipal] = useState(null);
  const [funcoesPrincipaisOptions, setFuncoesPrincipaisOptions] = useState([]);

  const [nivelInvestimento, setNivelInvestimento] = useState('');

  const [defensivosComDose, setDefensivosComDose] = useState([]);

  // State for Modal
  const [openModal, setOpenModal] = useState(false);
  const [selectedDefensivoRow, setSelectedDefensivoRow] = useState(null);
  const [regioesOptions, setRegioesOptions] = useState([]);
  const [regiao, setRegiao] = useState(null);
  const [preco, setPreco] = useState('');
  const [dataPreco, setDataPreco] = useState('');
  const [precos, setPrecos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [defensivosResponse, defensivosComDoseResponse, regioesResponse] = await Promise.all([
          api_amr.get('/retorna_nomes_comerciais_defensivos', {
            headers: { 'x-api-key': getToken() },
          }),
          api_amr.get('/retorna_defensivos_com_dose', {
            headers: { 'x-api-key': getToken() },
          }),
          api_amr.get('/retorna_regioes', {
            headers: { 'x-api-key': getToken() },
          }),
        ]);

        setDefensivosOptions(defensivosResponse.data);
        setDefensivosComDose(defensivosComDoseResponse.data);
        setRegioesOptions(regioesResponse.data);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (defensivo) {
      const fetchCulturas = async () => {
        try {
          const response = await api_amr.get('/retorna_culturas_defensivos', {
            headers: { 'x-api-key': getToken() },
            params: { id_marca: defensivo.id },
          });
          setCulturas(response.data);
        } catch (error) {
          console.error('Erro ao buscar culturas:', error);
        }
      };

      fetchCulturas();
    } else {
      setCulturas([]);
      setCultura(null);
    }
  }, [defensivo]);

  useEffect(() => {
    if (defensivo && cultura) {
      const fetchPragas = async () => {
        try {
          const response = await api_amr.get('/retorna_pragas_defensivos', {
            headers: { 'x-api-key': getToken() },
            params: {
              id_marca: defensivo.id,
              id_cultura: cultura.id_cultura,
            },
          });
          setPragas(response.data);
        } catch (error) {
          console.error('Erro ao buscar pragas:', error);
        }
      };

      fetchPragas();
    } else {
      setPragas([]);
      setPraga(null);
    }
  }, [defensivo, cultura]);

  useEffect(() => {
    if (segmento && segmento.funcoesPrincipais.length > 0) {
      setFuncoesPrincipaisOptions(segmento.funcoesPrincipais);
    } else {
      setFuncoesPrincipaisOptions([]);
      setFuncaoPrincipal(null);
    }
  }, [segmento]);

  const handleSubmit = async () => {
    try {
      await api_amr.post(
        '/salva_dose_defensivos',
        {
          id_marca: defensivo.id,
          id_cultura: cultura.id_cultura,
          id_praga: praga.id_praga,
          dose_min: doseMinima,
          dose_max: doseMaxima,
          segmento: segmento ? segmento.name : null,
          funcao_principal: funcaoPrincipal,
          nivel_investimento: nivelInvestimento,
        },
        {
          headers: { 'x-api-key': getToken() },
        }
      );
      alert('Dados salvos com sucesso!');

      const response = await api_amr.get('/retorna_defensivos_com_dose', {
        headers: { 'x-api-key': getToken() },
      });
      setDefensivosComDose(response.data);
    } catch (error) {
      console.error('Erro ao salvar dados:', error);
    }
  };

  const handleOpenModal = (row) => {
    setSelectedDefensivoRow(row);
    setOpenModal(true);
    fetchPrecos(row);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDefensivoRow(null);
    setRegiao(null);
    setPreco('');
    setDataPreco('');
    setPrecos([]);
  };

  const fetchPrecos = async (row) => {
    try {
      const response = await api_amr.get('/retorna_precos_defensivo', {
        headers: { 'x-api-key': getToken() },
        params: {
          id_marca: row.id_marca_comercial,
          id_cultura: row.id_cultura,
          id_praga: row.id_praga,
        },
      });
      setPrecos(response.data);
    } catch (error) {
      console.error('Erro ao buscar preços:', error);
    }
  };

  const handleSavePreco = async () => {
    try {
      await api_amr.post(
        '/salva_preco_defensivo',
        {
          id_marca: selectedDefensivoRow.id_marca_comercial,
          id_cultura: selectedDefensivoRow.id_cultura,
          id_praga: selectedDefensivoRow.id_praga,
          id_regiao: regiao.id_regiao,
          data_preco: dataPreco,
          preco: preco,
        },
        {
          headers: { 'x-api-key': getToken() },
        }
      );
      alert('Preço salvo com sucesso!');
      // Refresh the prices
      fetchPrecos(selectedDefensivoRow);
      // Reset form fields
      setRegiao(null);
      setPreco('');
      setDataPreco('');
    } catch (error) {
      console.error('Erro ao salvar preço:', error);
    }
  };

  return (
    <Paper className={classes.paper} elevation={3}>
      <Typography variant="h4" className={classes.title} align="center">
        Cadastro de Defensivos
      </Typography>
      <Grid container spacing={3}>
        {/* Defensivo */}
        <Grid item xs={12}>
          <Autocomplete
            options={defensivosOptions}
            getOptionLabel={(option) => option?.marca_comercial || ''}
            value={defensivo}
            onChange={(event, newValue) => setDefensivo(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Defensivo" variant="outlined" fullWidth />
            )}
          />
        </Grid>

        {/* Cultura */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={culturas}
            getOptionLabel={(option) => option?.CULTURA || ''}
            value={cultura}
            onChange={(event, newValue) => setCultura(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Cultura"
                variant="outlined"
                fullWidth
                disabled={!culturas.length}
              />
            )}
          />
        </Grid>

        {/* Praga */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={pragas}
            getOptionLabel={(option) => option?.PRAGA_NOME_COMUM || ''}
            value={praga}
            onChange={(event, newValue) => setPraga(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Praga"
                variant="outlined"
                fullWidth
                disabled={!pragas.length}
              />
            )}
          />
        </Grid>

        {/* Dose Mínima */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Dose Mínima"
            variant="outlined"
            fullWidth
            value={doseMinima}
            onChange={(e) => setDoseMinima(e.target.value)}
          />
        </Grid>

        {/* Dose Máxima */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Dose Máxima"
            variant="outlined"
            fullWidth
            value={doseMaxima}
            onChange={(e) => setDoseMaxima(e.target.value)}
          />
        </Grid>

        {/* Segmento */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={segmentosOptions}
            getOptionLabel={(option) => option.name}
            value={segmento}
            onChange={(event, newValue) => setSegmento(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Segmento" variant="outlined" fullWidth />
            )}
          />
        </Grid>

        {/* Função Principal */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={funcoesPrincipaisOptions}
            getOptionLabel={(option) => option}
            value={funcaoPrincipal}
            onChange={(event, newValue) => setFuncaoPrincipal(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Função Principal"
                variant="outlined"
                fullWidth
                disabled={!funcoesPrincipaisOptions.length}
              />
            )}
          />
        </Grid>

        {/* Nível de Investimento */}
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Nível de Investimento</FormLabel>
            <RadioGroup
              row
              value={nivelInvestimento}
              onChange={(event) => setNivelInvestimento(event.target.value)}
            >
              {niveisInvestimentoOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio color="primary" />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* Botão de Salvar */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            size="large"
            className={classes.button}
          >
            Salvar
          </Button>
        </Grid>

        {/* Tabela de Defensivos com Dose */}
        <Grid item xs={12} className={classes.table}>
          <Typography variant="h5" gutterBottom>
            Produtos com Doses Cadastradas
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="Defensivos com Dose">
              <TableHead>
                <TableRow>
                  <TableCell>Defensivo</TableCell>
                  <TableCell>Cultura</TableCell>
                  <TableCell>Praga</TableCell>
                  <TableCell>Dose Mínima</TableCell>
                  <TableCell>Dose Máxima</TableCell>
                  <TableCell>Segmento</TableCell>
                  <TableCell>Função Principal</TableCell>
                  <TableCell>Nível de Investimento</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {defensivosComDose.map((row) => (
                  <TableRow key={`${row.id_marca_comercial}-${row.id_cultura}-${row.id_praga}`}>
                    <TableCell>{row.marca_comercial}</TableCell>
                    <TableCell>{row.CULTURA}</TableCell>
                    <TableCell>{row.PRAGA_NOME_COMUM}</TableCell>
                    <TableCell>{row.dose_min}</TableCell>
                    <TableCell>{row.dose_max}</TableCell>
                    <TableCell>{row.segmento}</TableCell>
                    <TableCell>{row.funcao_principal}</TableCell>
                    <TableCell>{row.nivel_investimento}</TableCell>
                    <TableCell>
                      <Button variant="contained" color="primary" onClick={() => handleOpenModal(row)}>
                        +
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* Modal */}
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Adicionar Preço</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Região */}
            <Grid item xs={12}>
              <Autocomplete
                options={regioesOptions}
                getOptionLabel={(option) => option.nome_regiao}
                value={regiao}
                onChange={(event, newValue) => setRegiao(newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="Região" variant="outlined" fullWidth />
                )}
              />
            </Grid>
            {/* Data do Preço */}
            <Grid item xs={12}>
              <TextField
                label="Data do Preço"
                type="date"
                variant="outlined"
                fullWidth
                value={dataPreco}
                onChange={(e) => setDataPreco(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {/* Preço */}
            <Grid item xs={12}>
              <TextField
                label="Preço"
                variant="outlined"
                fullWidth
                value={preco}
                onChange={(e) => setPreco(e.target.value)}
              />
            </Grid>
            {/* Existing Prices */}
            {precos.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6">Preços Cadastrados</Typography>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Região</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Preço</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {precos.map((preco) => (
                        <TableRow key={preco.id_preco}>
                          <TableCell>{preco.nome_regiao}</TableCell>
                          <TableCell>{preco.data_preco}</TableCell>
                          <TableCell>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(preco.preco)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSavePreco} color="primary" variant="contained">
            Salvar Preço
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Defensivos;
